<template>
  <div class="wrapper static-article">
    <row>
      <column
        xs="12"
        md="2">
      </column>
      <column
        xs="12"
        md="8">
        <h1 class="ff-flama fs-xxl fw-light tt-uppercase mb-5">
          Need help?
        </h1>
        <div class="article-body clearfix mb-3">
          <p>
            Welcome to the IntraFish support page. If you can’t find what you’re looking for here, remember that you can always contact us directly by <a href="mailto:info@intrafish.com">email</a> or <a href="/contactus">telephone</a>.
          </p>
          <p>
            Do you have any questions about your subscription? Check our <a href="https://www.intrafish.com/myifm/faq/">FAQ</a>.
          </p>
          <p class="mt-5">
            <strong>Login</strong><br>
            If you need to be reminded of your user-id or password, please contact us at <a href="mailto:info@intrafish.com">info@intrafish.com</a> or call your nearest office. Please refer to your subscription number if possible.
          </p>
          <p class="mt-5">
            <strong>Cookies</strong><br>
            To login to IntraFish.com your browser must be set to accept cookies.
          </p>
          <p class="mt-5">
            <strong>Subscription payments</strong><br>
            All necessary details for your individual subscription can be found on the invoice.
          </p>
          <p class="mt-5">
            <strong>Support</strong><br>
            If you have any problems or questions contact us at <a href="mailto:info@intrafish.com">info@intrafish.com</a>.
          </p>
        </div>
      </column>
    </row>
  </div>
</template>

<script>
import { Page } from 'global-components';
export default {
  name: 'help-page',
  extends: Page
};
</script>
